export const bahasaMessages = {
  feature1Heading: "Pembelajaran yang khusus dibuat sesuai dengan kebutuhan",
  feature1Text: "Teka-teki dan Kuis yang seru. 1000 pertanyaan soal dan latihan yang dirancang sebagai gameplay yang seru.",
  feature2Heading: "Permainan Edukasi",
  feature2Text: "Belajar sambil bermain dengan games, dimana konsep belajar ada di dalam game yang seru ",
  feature3Heading: "Video yang menarik dengan konten animasi",
  feature3Text: "Perpaduan yang menarik antara penjelasan dari guru dan animasi, dimana pelajaran yang dijelaskan sesuai dengan kurikulum dan disesuaikan dengan  apa yang kamu temukan di kehidupan keseharianmu ",
  feature4Heading: "Kelas belajar dengan soal dan penjelasan dari guru",
  feature4Text: "Rasakan pengalaman belajar dengan Guru keren di Live Class. Selain itu kamu bisa chat langsung sama gurunya yang akan membantumu untuk lebih memahami pelajaran yang diberikan di sekolah mengajar dengan cara yang bikin kamu ngga bosan! Topik belajar sesuai dengan kurikulum lho.",
  feature5Heading: "Multiplayer untuk main bareng teman",
  feature5Text: "Fitur keren Multiplayer dimana kamu bisa bermain bareng temanmu Bermain lebih seru dengan teman-temanmu lewat mode multiplayer. Dapatkan poin lebih dengan menjadi yang tercepat menjawab dan jadilah yang tertinggi di papan angka! ",
  feature6Heading: "Papan peringkat & tangga peringkat untuk memotivasi kamu",
  feature6Text: "Persaingan bisa menyenangkan dan sehat! Papan peringkat mengajak kamu belajar cara bersaing yang sehat sambil bersenang-senang dengan teman-temanmu. Kamu juga akan memahami pentingnya latihan untuk jadi lebih baik!",
};

export const englishMessages = {
  feature1Heading: "Personalized self paced learning",
  feature1Text: "Fun Puzzles and Quizzes. 1000's of questions and questions training designed as an interesting gameplay.",
  feature2Heading: "Educational games",
  feature2Text: "Learning while playing with games, where the concept of learning is in an exciting game",
  feature3Heading: "Engaging video's and animated content",
  feature3Text: "An interesting mix of explanations from the teacher and animation, where the lessons are explained according to the curriculum and adapted to what you find in your daily life",
  feature4Heading: "Live classes with quizzes and doubt clearing",
  feature4Text: "Feel the experience of learning with cool Teachers in Live Class. In addition, you can chat directly with the teacher who will help you better understand the lessons given at the teaching school in a way that makes you less bored! Learning topics are in accordance with the curriculum, you know.",
  feature5Heading: "Multiplayer for fun with friends",
  feature5Text: "Multiplayer cool feature where you can play with your friends Play more fun with your friends through multiplayer mode. Get more points by being the fastest to answer and be the highest on the scoreboard!",
  feature6Heading: "Leaderboards and ranking ladders",
  feature6Text: "Competition can be fun and healthy! Leaderboards invite you to learn healthy ways of competing while having fun with your friends. You will also understand the importance of practice to get better!",
};