import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import CloudLeft from '../../../Assets/Images/features-header-cloud-left.png';
import CloudRight from '../../../Assets/Images/features-header-cloud-right.png';
import { englishMessages, bahasaMessages } from './FeaturesHeadingMessages';
import './Header.scss';

const Header = ({ isIndoSelected }) => {
  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='features-header' >

        <img src={CloudLeft} alt='Awan' className='features-header-cloud-left' />
        <img src={CloudRight} alt='Awan' className='features-header-cloud-right' />

        <h1 className='features-header-heading' >
          <FormattedMessage id='heading' />
          {/* Kids will love it
          <span className='tablet-line-break' > for sure!</span> */}
        </h1>
      </div>
    </IntlProvider>
  );
};

Header.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Header;