import React, { useState, useRef, useEffect } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import Slider from "react-slick";
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import Phone from '../../../Assets/Images/phone.png';
import TopNotch from '../../../Assets/Images/phone-top-notch.png';
import CustomAvatar from "../../../Assets/Images/SHOP.png";
import NewLesson from "../../../Assets/Images/EDUCATIONAL-LESSONS.png";
import CoinCollection from "../../../Assets/Images/COIN-PINTAR.png";
import MiniGames from "../../../Assets/Images/PINTAR-POD.png";
import LeaderBoard from "../../../Assets/Images/LEADERBOARD.png";
import Multiplayer from "../../../Assets/Images/MULTIPLAYER.png";

import Feature1 from "../../../Assets/Images/Feature1.png";
import Feature2 from "../../../Assets/Images/Feature2.png";
import Feature3 from "../../../Assets/Images/Feature3.png";
import Feature4 from "../../../Assets/Images/Feature4.png";
import Feature5 from "../../../Assets/Images/Feature5.png";
import Feature6 from "../../../Assets/Images/Feature6.png";

import Frame from "../../../Assets/Images/frame.png";
import FramePortrait from "../../../Assets/Images/frameportrait.png";

import Feature1Video from "../../../Assets/Videos/Feature1-Quiz.mp4";
import Feature2Video from "../../../Assets/Videos/Feature2-Minigame.mp4";
import Feature3Video from "../../../Assets/Videos/Feature3.mp4";
import Feature4Video from "../../../Assets/Videos/Feature4-Liveclass.mp4";
import Feature5Video from "../../../Assets/Videos/Feature5-Multiplayer.mp4";
import Feature6Video from "../../../Assets/Videos/Feature6-Leaderboard.mp4";

import ArrowButton from '../../../components/arrowButton/ArrowButton';
import { englishMessages, bahasaMessages } from './FeaturesMainMessages';
import './Main.scss';

const settings = {
  dots: true,
  autoplay: false,
  slidesToShow: 1,
};


const Main = ({ isIndoSelected }) => {

  const sliderRef = useRef(null);

  const [activeBoard, setActiveBoard] = useState('feature1');
  const [featureVideo, setFeatureVideo] = useState(Feature1Video);
  const [potrait, setPotrait] = useState(true);

  // Functions
  useEffect(() => {
    switch (activeBoard) {
      case 'feature1':
        setPotrait(true);
        return setFeatureVideo(Feature1Video);
      case 'feature2':
        setPotrait(false);
        return setFeatureVideo(Feature2Video);
      case 'feature3':
        setPotrait(false);
        return setFeatureVideo(Feature3Video);
      case 'feature4':
        setPotrait(false);
        return setFeatureVideo(Feature4Video);
      case 'feature5':
        setPotrait(true);
        return setFeatureVideo(Feature5Video);
      case 'feature6':
        setPotrait(true);
        return setFeatureVideo(Feature6Video);
      default:
        setPotrait(true);
        return setFeatureVideo(Feature1Video);
    }
  }, [activeBoard]);

  const onSlideChange = (index) => {
    switch (index) {
      case 0:
        return setActiveBoard('feature1');
      case 1:
        return setActiveBoard('feature2');
      case 2:
        return setActiveBoard('feature3');
      case 3:
        return setActiveBoard('feature4');
      case 4:
        return setActiveBoard('feature5');
      case 5:
        return setActiveBoard('feature6');
      default:
        return;
    }
  };

  const onPrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const onNextClick = () => {
    sliderRef.current.slickNext();
  };

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='features-main' >
        <div className='features-main-left' >
          <div className='features-main-left-arrow-left' >
            <ArrowButton type='left' isActive onClick={() => onPrevClick()} />
          </div>

          <img src={FramePortrait} alt="Frame" className={`features-main-left-phone-app frame-portrait ${potrait ? 'active' : ''}`} />
          <img src={Frame} alt="Frame" className={`features-main-left-phone-app frame-landscape ${!potrait ? 'active' : ''}`} />

          <ReactPlayer
            className={`video ${potrait ? 'potrait' : 'landscape'}`}
            url={featureVideo}
            loop={true}
            playing={true}
            muted={true}
          >
          </ReactPlayer>

          <div className='features-main-left-arrow-right' >
            <ArrowButton isActive onClick={() => onNextClick()} />
          </div>
        </div>

        {/* Right Side Desktop Version */}
        <div className='features-main-right' >

          {activeBoard === 'feature1' ? (
            <div className='features-main-box-big' >
              <h1 className='features-main-box-big-heading'>
                <FormattedMessage id='feature1Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature1Text' />
              </p>
            </div>
          ) : (
            <div
              className='features-main-box-small'
              onClick={() => setActiveBoard('feature1')}
            >
              <FormattedMessage id='feature1Heading' />
            </div>
          )}

          {activeBoard === 'feature2' ? (
            <div className='features-main-box-big' >
              <h1 className='features-main-box-big-heading'>
                <FormattedMessage id='feature2Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature2Text' />
              </p>
            </div>
          ) : (
            <div
              className='features-main-box-small'
              onClick={() => setActiveBoard('feature2')}
            >
              <FormattedMessage id='feature2Heading' />
            </div>
          )}

          {activeBoard === 'feature3' ? (
            <div className='features-main-box-big' >
              <h1 className='features-main-box-big-heading'>
                <FormattedMessage id='feature3Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature3Text' />
              </p>
            </div>
          ) : (
            <div
              className='features-main-box-small'
              onClick={() => setActiveBoard('feature3')}
            >
              <FormattedMessage id='feature3Heading' />
            </div>
          )}

          {activeBoard === 'feature4' ? (
            <div className='features-main-box-big' >
              <h1 className='features-main-box-big-heading'>
                <FormattedMessage id='feature4Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature4Text' />
              </p>
            </div>
          ) : (
            <div
              className='features-main-box-small'
              onClick={() => setActiveBoard('feature4')}
            >
              <FormattedMessage id='feature4Heading' />
            </div>
          )}

          {activeBoard === 'feature5' ? (
            <div className='features-main-box-big' >
              <h1 className='features-main-box-big-heading'>
                <FormattedMessage id='feature5Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature5Text' />
              </p>
            </div>
          ) : (
            <div
              className='features-main-box-small'
              onClick={() => setActiveBoard('feature5')}
            >
              <FormattedMessage id='feature5Heading' />
            </div>
          )}

          {activeBoard === 'feature6' ? (
            <div className='features-main-box-big' >
              <h1 className='features-main-box-big-heading'>
                <FormattedMessage id='feature6Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature6Text' />
              </p>
            </div>
          ) : (
            <div
              className='features-main-box-small'
              onClick={() => setActiveBoard('feature6')}
            >
              <FormattedMessage id='feature6Heading' />
            </div>
          )}

        </div>

        {/* Right Side Mobile Version */}
        <div className="features-main-right-mobile" >
          <Slider {...settings} afterChange={onSlideChange} ref={sliderRef} >
            <div className='features-main-box-big-mobile' >
              <h1 className='features-main-box-big-heading' >
                <FormattedMessage id='feature1Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature1Text' />
              </p>
            </div>
            <div className='features-main-box-big-mobile' >
              <h1 className='features-main-box-big-heading' >
                <FormattedMessage id='feature2Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature2Text' />
              </p>
            </div>
            <div className='features-main-box-big-mobile' >
              <h1 className='features-main-box-big-heading' >
                <FormattedMessage id='feature3Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature3Text' />
              </p>
            </div>
            <div className='features-main-box-big-mobile' >
              <h1 className='features-main-box-big-heading' >
                <FormattedMessage id='feature4Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature4Text' />
              </p>
            </div>
            <div className='features-main-box-big-mobile' >
              <h1 className='features-main-box-big-heading' >
                <FormattedMessage id='feature5Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature5Text' />
              </p>
            </div>
            <div className='features-main-box-big-mobile' >
              <h1 className='features-main-box-big-heading' >
                <FormattedMessage id='feature6Heading' />
              </h1>
              <p className='features-main-box-big-text' >
                <FormattedMessage id='feature6Text' />
              </p>
            </div>
          </Slider>
        </div>

      </div>
    </IntlProvider>
  );
};

Main.propTypes = {
  isIndoSelected: PropTypes.bool
};

export default Main;