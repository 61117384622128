import React, { useContext } from "react";
import Layout from "../components/Layout";
import PropTypes from 'prop-types';
import { LangStateContext } from "../context/LangContext";
import Header from '../sections/featuresPage/header/Header';
import Main from '../sections/featuresPage/main/Main';
import CheckPremium from '../sections/homePage/checkPremium/CheckPremium';
import '../styles/FiturPage.scss';
import SEO from '../components/seo/Seo';

const FeaturePage = () => {
  const { isIndoSelected, } = useContext(LangStateContext);

  return (
    <Layout>
      <SEO 
        title='Titik Pintar - Fitur menarik dari Titik Pintar'
        description='Pembelajaran, Permainan Edukasi beserta Video yang menarik dengan konten animasi tersedia di Titik Pintar. Mainkan dan tunjukan papan peringkat kamu.'
        keywords='Pembelajaran, Permainan Edukasi, Video menarik, Konten Animasi, Papan Peringkat, Titik Pintar, Penjelassan Guru'
      />
      <div className='homepage' >
        <Header isIndoSelected={isIndoSelected} />
        <Main isIndoSelected={isIndoSelected} />
        {/* <div className='features-check-premium-container' >
          <CheckPremium isIndoSelected={isIndoSelected} />
        </div> */}
      </div>
    </Layout>
  );
};

FeaturePage.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default FeaturePage;