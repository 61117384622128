import React from 'react';
import PropTypes from 'prop-types';
import RightArrow from '../../Assets/Images/right-arrow.png';
import './ArrowButton.scss';

const ArrowButton = ({ isActive, onClick, type }) => {
  return (
    <button 
      className={`arrow-btn 
        ${isActive ? 'active' : ''}
        ${type === 'left' ? 'left' : '' }
      `}
      onClick={onClick}
    >
      <img src={RightArrow} alt='Tanda panah kanan' />
    </button>
  );
};

ArrowButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default ArrowButton;